import React from 'react'
import Card from '~/components/globals/Card'
import Button from '~/components/globals/Button'
import SvgIcon from '~/components/common/SvgIcon'
import Link from 'next/link'
import { useTenant } from '~/hooks/tenant'

type Props = {
  className?: string
}
const CustomGuide = ({ className }: Props) => {
  const { tenant } = useTenant()

  return tenant.guideLink ? (
    <Card className={className}>
      <div className="flex items-center">
        <h4 className="flex-grow">
          <div className="fs-18 fw-500">ESOP guide</div>
        </h4>
        <Link href={tenant.guideLink} passHref>
          <a target="_blank">
            <Button variant="icon" textColor="grey-300" icon="link-external-01" />
          </a>
        </Link>
      </div>

      <div className="my-24 flex justify-between fs-14">
        Learn more about your ESOP & equity in general.
      </div>

      <Link href={tenant.guideLink} passHref>
        <a target="_blank">
          <Button variant="text" size="xs">
            <>
              Open ESOP guide
              <SvgIcon icon="chevron-right" height={14} className="ml-4" />
            </>
          </Button>
        </a>
      </Link>
    </Card>
  ) : null
}

export default CustomGuide
