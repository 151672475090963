import DocumentSvg from '~/assets/svgs/activity/document.svg'
import Dropdown, { type IDropdownOption } from '~/components/globals/Dropdown'
import Pressable from '~/components/globals/Pressable'
import TooltipWrap from '~/components/globals/TooltipWrap'
import type DocumentModel from '~/models/Document'
import styles from './Document.module.scss'
import { useTenant } from '~/hooks/tenant'
import { useAcl } from '~/hooks/user'
import { sleep } from '~/utils/helpers'
import { useModals } from '~/hooks/modals'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'
import { useAlerts } from '~/hooks/alerts'
import { useState } from 'react'
import AssetProcess from '~/models/AssetProcess'

type Props = {
  assetProcess?: AssetProcess
  document?: DocumentModel
  className?: string
  showMissingPlaceholders?: boolean
  reload?: () => Promise<void>
}

export default function Document({
  className,
  assetProcess,
  document,
  showMissingPlaceholders,
  reload
}: Props) {
  const { tenantApi, tenant } = useTenant()
  const user = useAcl()
  const modals = useModals()
  const alerts = useAlerts()
  const [isLoading, setIsLoading] = useState(false)
  const [isReminderSent, setIsReminderSent] = useState(false)

  const preview = () => {
    if (document) {
      window.open(tenantApi.getUrl(`documents/${document.id}/content`))
    }
  }

  const download = () => {
    if (document) {
      tenantApi.$download(`documents/${document.id}/download`)
    }
  }

  const update = async () => {
    if (document) {
      setIsLoading(true)
      tenantApi
        .$post(`documents/${document.id}/regenerate`)
        .then(
          async () => {
            alerts.open({
              text: 'Document updated successfully',
              type: 'success'
            })
            if (reload) {
              await reload()
            }
          },
          (err) => {
            alerts.error(err)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const sendReminder = async () => {
    if (document) {
      const result = await modals.confirm({
        title: 'Send reminders?',
        text: (
          <div className="fs-14">
            <p className="pt-8 pb-16">
              You are about to send reminders to everybody who haven`&apos;t signed the document
              yet. They will receive a new email with the link to DocuSign to complete the signing
              process.
            </p>
            <p className="pb-16">The reminders will be sent to:</p>
            <ul className={clsx(styles.reminderModalList, 'ml-30')}>
              {assetProcess?.steps
                .flatMap((step) => step.documents)
                .flatMap((detail) => detail?.recipients)
                .filter((recipient) => recipient?.status === 'PENDING')
                .map((recipient) => (
                  <li key={recipient?.email}>
                    <strong>{recipient?.name}</strong>
                    {', ' + recipient?.email}
                  </li>
                ))}
            </ul>
          </div>
        )
      })
      if (result) {
        setIsLoading(true)
        setIsReminderSent(true)
        tenantApi
          .$post(`documents/${document.id}/reminder`)
          .then(
            async () => {
              alerts.open({
                text: 'Reminders emails were sent',
                type: 'success'
              })
              setInterval(() => setIsReminderSent(false), 60000)
            },
            (err) => {
              alerts.error(err)
              setIsReminderSent(false)
            }
          )
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  const signAs = async (role: string) => {
    if (document) {
      await tenantApi.$post(`documents/${document.id}/sign`, { role })
      if (reload) {
        await sleep(2000)
        await reload()
      }
    }
  }

  const signAsCompany = async () => {
    await signAs('company')
  }

  const signAsParticipant = async () => {
    await signAs('participant')
  }

  const declineAs = async (role: string) => {
    if (document) {
      return modals.open(
        import('~/modals/sign/Decline'),
        {},
        { documentId: document.id, role, reload }
      )
    }
  }

  const declineAsCompany = async () => {
    await declineAs('company')
  }

  const declineAsParticipant = async () => {
    await declineAs('participant')
  }

  const documentOptions: IDropdownOption[] = [
    {
      icon: 'eye',
      label: 'Preview',
      action: preview
    },
    {
      icon: 'download-01',
      label: 'Download',
      action: download
    }
  ]

  if (document?.isPendingSignatures && user.can('manage_documents')) {
    documentOptions.splice(0, 0, {
      icon: 'send-01',
      label: 'Send reminder',
      disabled: isLoading || isReminderSent,
      action: sendReminder
    })
  }

  if (document?.isDraft && user.can('manage_documents')) {
    documentOptions.push({
      icon: 'repeat-04',
      label: 'Update',
      disabled: isLoading,
      action: update
    })
  }

  if (tenant.isSelfSign && tenant.isDemo) {
    if (document?.waitingForRoles.includes('participant')) {
      documentOptions.push(
        {
          icon: 'edit-01',
          label: user.isManager ? 'Sign as participant' : 'Sign',
          action: signAsParticipant
        },
        {
          icon: 'x-close',
          label: user.isManager ? 'Decline as participant' : 'Decline',
          action: declineAsParticipant
        }
      )
    }

    if (document?.waitingForRoles.includes('company') && user.isManager) {
      documentOptions.push(
        {
          icon: 'edit-01',
          label: 'Sign as company',
          action: signAsCompany
        },
        {
          icon: 'x-close',
          label: 'Decline as company',
          action: declineAsCompany
        }
      )
    }
  }

  return (
    <div className={clsx(styles.document, className)}>
      <div className={clsx(styles.iconHolder, 'mr-16')}>
        <span className="skeleton-animation">
          <DocumentSvg />
        </span>
        <div className={clsx(styles.label, 'skeleton-extension')}>{document?.extension}</div>
      </div>
      <div className={styles.documentNameContainer}>
        {document ? (
          <TooltipWrap title={document.name}>
            <div className={styles.documentName}>{document.name}</div>
          </TooltipWrap>
        ) : (
          <div className={styles.documentName}>
            <span className="skeleton-name" />
          </div>
        )}
        <div className="fs-12 color-grey mt-4 skeleton-status">{document?.displayStatus ?? ''}</div>
      </div>
      {showMissingPlaceholders && !!document?.missingPlaceholders?.length && (
        <TooltipWrap
          title={
            <>
              <div className="mb-5">This data is missing to generate the document correctly:</div>
              {document.missingPlaceholders.map((placeholder, index) => (
                <div key={index}>* {placeholder}</div>
              ))}
              <div className="mt-10">
                Please add these and regenerate the document before publishing the process.
              </div>
            </>
          }
          className={styles.tooltip}
        >
          <div className={clsx(styles.tooltipIcon, 'skeleton-hide')}>
            <SvgIcon icon="alert-triangle" height={16} className="color-primary" />
          </div>
        </TooltipWrap>
        // <SvgIcon icon="alert-triangle" height={16} className="mr-4" />
      )}
      {document && !document?.isQueued && (
        <div className={styles.documentMenu}>
          <Dropdown
            placement={'bottom-end'}
            activator={({ toggle }) => (
              <Pressable onClick={toggle}>
                <SvgIcon icon="dots-vertical" height={20} />
              </Pressable>
            )}
            options={documentOptions}
            offset={[17, 24]}
          />
        </div>
      )}
    </div>
  )
}
